import { useState, useMemo } from 'react'
import { useQueryClient, useSuspenseInfiniteQuery } from '@tanstack/react-query'
import { getJobs } from 'api/odb'
import useClient from './useClient'
import { JobsQueryResponse } from 'types/jobs'
import { InstallationId, InstanceId } from 'types/installations'
import { useUser } from '@matillion/hub-client'

interface JobsParams {
  installationId: InstallationId
  instanceId: InstanceId
}

const itemsPerPage = 20
const getNextPage = (lastPage: Pick<JobsQueryResponse, 'nextPage'>) =>
  lastPage.nextPage

const useGetJobs = (jobParams: JobsParams) => {
  const { installationId, instanceId } = jobParams
  const client = useClient()
  const queryClient = useQueryClient()
  const [name, setName] = useState('')
  const { organisation } = useUser()

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    refetch
  } = useSuspenseInfiniteQuery<JobsQueryResponse>({
    queryKey: [
      organisation.id,
      'jobs',
      `${installationId}-${instanceId}-${name}`
    ],
    queryFn: async ({ pageParam }) => {
      return getJobs(
        {
          installationId,
          instanceId,
          name,
          page: pageParam as number,
          itemsPerPage,
          end: new Date()
        },
        client
      )
    },
    initialPageParam: 0,
    getNextPageParam: getNextPage
  })

  const jobs = useMemo(
    () => data?.pages.flatMap((page) => page.content),
    [data?.pages]
  )

  const searchByName = (keyword: string) => setName(keyword)
  const refresh = () => {
    queryClient.removeQueries({
      queryKey: [organisation.id, 'jobs', `${installationId}-${instanceId}-`]
    })
    if (name) {
      setName('')
    } else {
      void refetch()
    }
  }

  return {
    jobs,
    searchByName,
    name,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    refresh
  }
}

export { useGetJobs, getNextPage }
