import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { getDataset } from 'api/dgs'
import useDgsClient from './useDgsClient'
import { Dataset } from 'types/dgs'
import { useUser } from '@matillion/hub-client'

export const useGetDataset = (datasetId: string) => {
  const client = useDgsClient()
  const { organisation } = useUser()
  return useQuery<Dataset, AxiosError>({
    queryKey: [organisation.id, 'dataset', datasetId],
    queryFn: async () => getDataset(client, datasetId),
    refetchOnMount: false,
    staleTime: 5 * 60 * 1000
  })
}
