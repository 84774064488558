import { useQuery } from '@tanstack/react-query'
import useProjectsClient from './useProjectsClient'
import { getProjects } from '../projects'
import { useUser } from '@matillion/hub-client'

export const useGetProjects = () => {
  const client = useProjectsClient()
  const { organisation } = useUser()
  return useQuery({
    queryKey: [organisation.id, 'projects'],
    queryFn: async () => getProjects(client),
    retry: 1,
    staleTime: 60_000
  })
}
