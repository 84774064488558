import { useInfiniteQuery } from '@tanstack/react-query'
import useEosClient from './useEosClient'
import { getSteps, StepsResponse } from 'api/eos'
import { PipelineStatus, Step } from 'types/eos'
import { collateSteps } from '../utils/collateSteps'
import { useUser } from '@matillion/hub-client'

const getNextPage = (lastPage: StepsResponse) => lastPage

export const useGetStepsInfinite = (
  pipelineId: string,
  status?: PipelineStatus
) => {
  const client = useEosClient()
  const { organisation } = useUser()
  const { data, isFetching, fetchNextPage, isFetchingNextPage, isError } =
    useInfiniteQuery({
      queryKey: [organisation.id, 'steps', pipelineId, status],
      queryFn: async ({ pageParam }) => {
        const prevPage = pageParam

        const offset =
          typeof prevPage?.offset === 'number'
            ? prevPage.offset + prevPage.limit
            : 0
        const limit = prevPage?.limit ?? undefined

        return getSteps(client, pipelineId, offset, limit, status)
      },
      initialPageParam: undefined,
      getNextPageParam: getNextPage,
      refetchOnMount: false
    })

  const allSteps: Step[] | undefined = data?.pages.flatMap(
    (page) => page?.results
  )
  const collatedSteps = allSteps ? collateSteps(allSteps) : undefined

  const hasMore = Boolean(data?.pages[data.pages.length - 1]?._links.next)

  const fetchNext = () => {
    if (hasMore && !isFetching) {
      void fetchNextPage()
    }
  }

  return {
    steps: collatedSteps,
    isFetching,
    isRefreshing: isFetching && !isFetchingNextPage,
    isPaginating: isFetchingNextPage,
    hasMore,
    isError,
    fetchNextPage: fetchNext
  }
}
