import { useQueryClient } from '@tanstack/react-query'
import classes from './RefreshButton.module.scss'
import { Icon } from '@matillion/component-library'
import { useUser } from '@matillion/hub-client'

const RefreshButton = () => {
  const queryClient = useQueryClient()
  const { organisation } = useUser()

  return (
    <button
      type="button"
      aria-label="Refresh"
      className={classes.Refresh}
      onClick={() => {
        void queryClient.resetQueries({
          queryKey: [organisation.id, 'activity']
        })
      }}
    >
      <Icon.Refresh />
    </button>
  )
}

export default RefreshButton
