import { useQuery } from '@tanstack/react-query'
import { DatasetLineageResponse, getDatasetLineage } from 'api/dgs'
import { AxiosError } from 'axios'
import useDgsClient from './useDgsClient'
import { useUser } from '@matillion/hub-client'

export const useGetDatasetLineage = (datasetId: string) => {
  const client = useDgsClient()
  const { organisation } = useUser()

  return useQuery<DatasetLineageResponse, AxiosError>({
    queryKey: [organisation.id, 'dataset-lineage', datasetId],
    queryFn: async () => getDatasetLineage(client, datasetId),
    refetchOnMount: false,
    staleTime: 5 * 60 * 1000
  })
}
