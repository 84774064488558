import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { ActivityFilterQueryParams, getActivityFilters } from 'api/eos'
import { useFilters } from 'context/FilterContext'
import { makeISODatesFromFilters } from 'context/FilterContext/makeISODatesFromFilters'
import { useCallback, useMemo } from 'react'
import useEosClient from './useEosClient'
import { useUser } from '@matillion/hub-client'

export type QueryOptions = Pick<UseQueryOptions, 'enabled'>

export interface ActivityFilterParams {
  fields?: ActivityFilterQueryParams['fields']
}

export const useActivityFilters = (
  params: ActivityFilterParams,
  queryOptions?: QueryOptions
) => {
  const client = useEosClient()
  const { organisation } = useUser()
  const {
    filters: { startedFrom, startedTo, timeFrame }
  } = useFilters()
  const queryParams: ActivityFilterQueryParams = useMemo(
    () => ({
      fields: params.fields,
      ...makeISODatesFromFilters({
        startedFrom,
        startedTo,
        timeFrame
      })
    }),
    // use the discrete fields so we don't have to rerun the query when other filters change
    [startedFrom, startedTo, timeFrame, params.fields]
  )
  const queryFn = useCallback(
    async () => getActivityFilters(client, queryParams),
    [client, queryParams]
  )
  const { data, isLoading, isError } = useQuery({
    queryKey: [organisation.id, 'activityFilters', queryParams],
    queryFn,
    ...queryOptions
  })

  return useMemo(
    () => ({
      filters: data,
      isLoading,
      isError
    }),
    [data, isLoading, isError]
  )
}
