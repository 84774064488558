import { useQuery } from '@tanstack/react-query'
import { ColumnLineageResponse, getColumnLineage } from 'api/dgs'
import { AxiosError } from 'axios'
import useDgsClient from './useDgsClient'
import { useUser } from '@matillion/hub-client'

export const useGetColumnLineage = (datasetId: string) => {
  const client = useDgsClient()
  const { organisation } = useUser()
  return useQuery<ColumnLineageResponse, AxiosError>({
    queryKey: [organisation.id, 'column-lineage', datasetId],
    queryFn: async () => getColumnLineage(client, datasetId),
    refetchOnMount: false,
    staleTime: 30 * 60 * 1000
  })
}
