import { useUser } from '@matillion/hub-client'
import { QueryKey } from '@tanstack/react-query'
import { Base64 } from 'js-base64'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { PipelineStatus } from 'types/eos'

export const timeframes = [
  '15m',
  '1h',
  '4h',
  '24h',
  '3d',
  '5d',
  '7d',
  '30d',
  '*',
  'custom'
] as const

export type TimePeriod = (typeof timeframes)[number]

export const isTimePeriod = (value: string | null): value is TimePeriod =>
  timeframes.includes(value as TimePeriod)

export interface Filters {
  timeFrame?: TimePeriod
  startedFrom?: string
  startedTo?: string
  search: string
  status?: PipelineStatus[]
}

const stringKeys = ['startedTo', 'startedFrom', 'search'] as const

interface ContextValue {
  filters: Filters
  updateFilters: (updates: Partial<Filters>) => void
  queryKey: QueryKey
  encoded: string
}

const FilterContext = createContext<ContextValue | undefined>(undefined)

function fromSearchParams(searchParams: URLSearchParams): Partial<Filters> {
  const result: Partial<Filters> = {}

  stringKeys.forEach((key) => {
    const value = searchParams.get(key)

    if (value) {
      result[key] = value
    }
  })

  const timeFrame = searchParams.get('timeFrame')

  if (isTimePeriod(timeFrame)) {
    result.timeFrame = timeFrame
  }

  if (searchParams.has('status') && searchParams.get('status') !== '') {
    result.status = searchParams.get('status')?.split(',') as PipelineStatus[]
  }

  return result
}

export const FilterProvider = ({ children }: { children: ReactNode }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [filters, setFilters] = useState<Filters>({
    timeFrame: '24h',
    search: '',
    ...fromSearchParams(searchParams)
  })
  const { organisation } = useUser()

  useEffect(() => {
    const newSearchParams = new URLSearchParams()

    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        newSearchParams.set(key, value)
      }
    })

    setSearchParams(newSearchParams, { replace: true })
  }, [filters, setSearchParams])

  const updateFilters = useCallback((updates: Partial<Filters>) => {
    setFilters((prev) => ({ ...prev, ...updates }))
  }, [])

  const contextValue = useMemo(() => {
    return {
      filters,
      updateFilters,
      queryKey: [organisation.id, 'activity', filters],
      encoded: encodeURIComponent(Base64.encode(JSON.stringify(filters)))
    }
  }, [filters, organisation.id, updateFilters])

  return (
    <FilterContext.Provider value={contextValue}>
      {children}
    </FilterContext.Provider>
  )
}

export const useFilters = () => {
  const context = useContext(FilterContext)
  if (context === undefined) {
    throw new Error('useFilters must be used within a FilterProvider')
  }
  return context
}

export const useRememberedFilters = () => {
  const [searchParams] = useSearchParams()
  const raw = searchParams.get('filters')
  return useMemo(() => {
    if (!raw) {
      return undefined
    }
    try {
      const filters = JSON.parse(Base64.decode(raw)) as Filters
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return new URLSearchParams(filters as any)
    } catch (e) {
      console.error(e)
      return undefined
    }
  }, [raw])
}
