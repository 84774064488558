const DATASET_TYPE_LOOKUP: Record<string, string> = {
  SNOWFLAKE: 'Snowflake',
  JIRA: 'Jira',
  REDSHIFT: 'AmazonRedshift',
  DATABRICKS: 'Databricks',
  SALESFORCE: 'Salesforce',
  SURVEYMONKEY: 'SurveyMonkey',
  GMAIL: 'Gmail',
  MAILCHIMP: 'Mailchimp'
}

export default DATASET_TYPE_LOOKUP
